import React from 'react'
import { graphql } from 'gatsby'

import EpisodeCard from '../components/episode-card'
import Layout from '../components/layout'
import VideoPlayer from  '../components/video-player'

export const query = graphql`
    query ($show: String!, $season: Int!, $episode: Int!) {
        showsJson(slug: {eq: $show}) {
            name
        }
        episodesJson(show: {eq: $show}, season: {eq: $season}, episode: {eq: $episode}) {
            title
            summary
            youtubeId
        }
        allEpisodesJson(sort: {order: [ASC, ASC], fields: [season, episode]},
            filter: {show: {eq: $show}, season: {eq: $season}, episode: {gt: $episode}},
            limit: 4) {
            edges {
                node {
                    title
                    season
                    episode
                    fields {
                        slug
                        url
                        thumbnailUrl
                    }
                }
            }
        }
    }
`

const Episode = ({data}) => {
    const newEpisodes = data.allEpisodesJson.edges;
    return (
        <Layout theme="dark">
            <VideoPlayer youtubeId={data.episodesJson.youtubeId} />
            <h2>{data.showsJson.name}</h2>
            <h3>{data.episodesJson.title}</h3>
            <p>{data.episodesJson.summary}</p>

            <br />
            <h3>À suivre</h3>
            <div class="row">
                {newEpisodes.map(({node}) => (
                    <EpisodeCard episode={node} show={data.showsJson} />
                ))}
            </div>
        </Layout>
    )
}

export default Episode