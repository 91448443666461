import React, { useEffect } from 'react'

import '../../node_modules/plyr/dist/plyr.css'

// TODO : enlever les helper boostrap et l'autoplay, ajouter plyr

const VideoPlayer = ({youtubeId}) => {

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                const Plyr = require('plyr');
                const player = new Plyr('#player', {
                    settings: [],
                    tooltips: { controls: true, seek: true },
                    i18n: {
                        restart: 'Recommencer',
                        rewind: 'Rewind {seektime}s',
                        play: 'Lire',
                        pause: 'Pause',
                        fastForward: 'Forward {seektime}s',
                        seek: 'Seek',
                        seekLabel: '{currentTime} of {duration}',
                        played: 'Played',
                        buffered: 'Buffered',
                        currentTime: 'Current time',
                        duration: 'Durée',
                        volume: 'Volume',
                        mute: 'Silence',
                        unmute: 'Unmute',
                        enterFullscreen: 'Plein écran',
                        exitFullscreen: 'Quitter le plein écran',
                        frameTitle: 'Player for {title}',
                        loop: 'Loop',
                        start: 'Start',
                        end: 'End',
                        all: 'All',
                        reset: 'Reset',
                        disabled: 'Disabled',
                        enabled: 'Enabled',
                    },
                    keyboard: { focused: true, global: true },
                    //ratio: "4:3"
                })

                
		const introDuration = 45;

                player.once('ready', event => {
                    insertSkipIntroButton();
                });

                function insertSkipIntroButton() {
                    var template = document.createElement('template');
                    template.innerHTML = '<button id="skip-button" type="button" class="plyr__control custom-plyr-text-button">Passer le générique</button>';
                    var plyrControls = document.getElementsByClassName("plyr__controls")[0];
                    plyrControls.insertBefore(template.content.firstChild, plyrControls.firstChild);
        
                    document.getElementById("skip-button").onclick = function() {
                        player.currentTime = introDuration;
                    };
        
                    var styleTemplate = document.createElement('template');
                    styleTemplate.innerHTML = '<style id="skip-button-style">.plyr__controls.hide-skip-button {margin-left: -' + document.getElementById("skip-button").offsetWidth + 'px;}</button>';
                    document.body.appendChild(styleTemplate.content.firstChild);
                }
        }
    });

    return (
        <div id="player" class="embed-responsive embed-responsive-16by9 plyr__video-embed">
            <iframe class="embed-responsive-item"
            title="TODO"
            src={`https://www.youtube.com/embed/${youtubeId}?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1&autoplay=1`}
            allowfullscreen
            allowtransparency
            allow="autoplay; encrypted-media" />
        </div>
    )
}

export default VideoPlayer